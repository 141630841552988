/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConcepts = /* GraphQL */ `
  query GetConcepts($name: String!) {
    getConcepts(name: $name) {
      name
      metaphor
      description
      usedIn
      related
      __typename
    }
  }
`;
export const listConcepts = /* GraphQL */ `
  query ListConcepts(
    $filter: TableConceptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        metaphor
        description
        usedIn
        related
        __typename
      }
      nextToken
      __typename
    }
  }
`;
